import React from 'react';
import PropTypes from 'prop-types';

import classes from './Button.module.css';

export const Button = ({ children, btnType, ...btnArgs }) => {
  let assignedClasses = [];
  if (btnType) {
    assignedClasses = btnType.split(' ');
  }

  return (
    <button
      className={assignedClasses
        .map(el => {
          return classes[el];
        })
        .join(' ')}
      {...btnArgs}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  btnType: PropTypes.string.isRequired,
};

export default Button;
