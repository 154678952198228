import * as Constants from '../constants';

const initialState = {
  activeTab: 'income',
};

export const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.CHANGE_TAB:
      state = {
        ...state,
        activeTab: action.payload.tab,
      };
      break;
    default:
      break;
  }

  return state;
};
