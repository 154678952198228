import { LOGIN, LOGOUT } from '../constants';

import instance from '../axios-docs';

export const loginUser = (type, data) => {
  // return {
  //   type: LOGIN,
  //   payload: { data: { userId: 'testUser', token: 'testToken' } },
  // };
  if (type === 'login') {
    return {
      type: LOGIN,
      payload: instance.post('/auth/login', {
        loginType: type,
        ...data,
      }),
    };
  }
  return {
    type: LOGIN,
    payload: instance.post('/auth/login', {
      loginType: type,
      code: data,
    }),
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT,
    // payload: { userId: 'testUser', token: 'testToken' },
  };
};
