import {
  ADD_QRCODE,
  GET_DOCUMENTS,
  GET_DOCUMENT,
  UPDATE_DOCUMENT,
  CLOSE_DOCUMENT,
  CHOOSE_PARTY,
  CREATE_UNPACK,
  SHOW_UNPACK,
} from '../constants';

import instance from '../axios-docs';

export const getDocuments = tab => {
  console.log('in getDocuments');
  console.log(tab);

  return {
    type: GET_DOCUMENTS,
    payload: instance.get('/docs', { params: { docType: tab } }),
  };
};

export const openDocument = uid => {
  return {
    type: GET_DOCUMENT,
    payload: instance.get('/docs/tovar', { params: { uid } }),
  };
};

export const closeDocument = () => {
  return {
    type: CLOSE_DOCUMENT,
  };
};

export const updateDocument = (id, data) => {
  console.log(id);
  console.log(data);

  return {
    type: UPDATE_DOCUMENT,
    payload: instance.post('/docs/tovar', { id, tovars: data }),
  };
};

export const addQRCode = data => {
  return {
    type: ADD_QRCODE,
    payload: data,
  };
};

export const chooseParty = (uid, uidParty) => {
  return {
    type: CHOOSE_PARTY,
    payload: { uid, uidParty },
  };
};

export const unpack = show => {
  return {
    type: SHOW_UNPACK,
    payload: show,
  };
};

export const createUnpack = data => {
  return {
    type: CREATE_UNPACK,
    payload: instance.post('/docs/unpack', { ...data }),
  };
};
