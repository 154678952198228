import { createStore, combineReducers, applyMiddleware } from 'redux';

// Middleware
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import { authReducer } from './reducers/authReducer';
import { dataReducer } from './reducers/dataReducer';
import { menuReducer } from './reducers/menuReducer';
// import { siteReducer } from './reducers/siteReducer';
// import { supervisorReducer } from './reducers/supervisorReducer';
// import { activityReducer } from './reducers/activityReducer';
// import { helpReducer } from './reducers/helpReducer';
// import { stripeReducer } from './reducers/stripeReducer';

// Reducers
const reducers = combineReducers({
  auth: authReducer,
  data: dataReducer,
  menu: menuReducer,
  //   sites: siteReducer,
  //   supervisors: supervisorReducer,
  //   activities: activityReducer,
  //   help: helpReducer,
  //   stripe: stripeReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return reducers(state, action);
};

const middleware = applyMiddleware(promise, logger, thunk);

const store = createStore(rootReducer, middleware);
export default store;
