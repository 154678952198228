import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BrowserMultiFormatReader } from '@zxing/library/esm5';
import Button from '../UI/Button';
import Input from '../UI/Input';
import classes from './MultiReader.module.css';

const MultiReader = ({ addCode, active }) => {
  const [result, setresult] = useState({
    data: 'Нажмите кнопку для начала сканирования',
  });
  const [sizeScan, setsizeScan] = useState(1080);
  const [scanCount, setscanCount] = useState(0);
  const [automode, setautomode] = useState(active);
  let constraints = {
    video: {
      facingMode: { exact: 'environment' },
      aspectRatio: 1,
      width: sizeScan,
      height: sizeScan,
    },
  };
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(async mediaStream => {
        const el = document.querySelector('#video');
        el.srcObject = mediaStream;
        el.play();
        if (automode) {
          handleScan();
        }
      })
      .catch(error => setresult({ data: error.name || error }));
    return () => {};
  }, []);

  useEffect(() => {
    if (automode) {
      setTimeout(() => {
        handleScan();
      }, 500);
    }
    return () => {};
  }, [scanCount]);

  const reader = new BrowserMultiFormatReader();
  const handleScan = () => {
    setresult({ data: 'Идет сканирование...' });

    reader
      .decodeOnceFromConstraints(constraints, 'video')
      .then((decoded, err) => {
        if (decoded) {
          setresult({ data: decoded.text });
          addCode(decoded.text);
          setscanCount(scanCount + 1);
        }
        if (err) {
          console.error(err);
        }
      })
      .catch(err => console.error(err));
  };

  return (
    <div className={classes.wrapper}>
      <video
        id="video"
        width="320px"
        height="320px"
        style={{ border: '1px solid gray' }}
      ></video>
      <div className={classes.info}>
        <div className={classes.result}>{result.data}</div>
        <Input
          type="checkbox"
          label=" Ручной"
          name="handtype"
          value={!automode}
          onChange={e => setautomode(!automode)}
        />
      </div>
      <Button btnType="scanButton" onClick={handleScan}>
        Сканировать
      </Button>
    </div>
  );
};

MultiReader.propTypes = {
  addCode: PropTypes.func,
  active: PropTypes.bool,
};

MultiReader.defaultProps = {
  addCode: () => {},
  active: false,
};

export default MultiReader;
