import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import MainMenu from '../containers/MainMenu';
import DocumentList from '../containers/DocumentList';

import classes from './MainPage.module.css';

export const MainPage = () => {
  return (
    <div className={classes.wrapper}>
      <MainMenu />
      <DocumentList />
    </div>
  );
};

export default MainPage;
