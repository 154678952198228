import React, { useState } from 'react';
import PropTypes from 'prop-types';

import MultiReader from '../MultiReader';
import Button from '../UI/Button';
import classes from './Unpack.module.css';

export const Unpack = ({ save, close }) => {
  const [codeOut, setcodeOut] = useState('');
  const [error, seterror] = useState('');
  const [showCodes, setshowCodes] = useState(false);
  const [codesIn, setcodesIn] = useState([]);
  const addCode = code => {
    if (code.length !== 20 && code[code.length - 1] !== '=') {
      seterror(
        <div>
          <div>Cчитан неверный код!!!</div>
          <div>{code}</div>
        </div>
      );
      return;
    }
    if (codeOut) {
      setcodesIn([...codesIn, code]);
    } else {
      setcodeOut(code);
    }
  };
  const datamatrixView = code => {
    if (code.length === 20) {
      return code;
    }
    if (`${code[0]}${code[1]}` === '01') {
      return `${code.slice(2, 16)}  ${code.slice(18, 31)}`;
    }
    return `${code.slice(3, 17)}  ${code.slice(19, 32)}`;
  };
  const dataOutView = (
    <div className={classes.codesOut}>{codeOut}</div>
  );
  const dataInView = (
    <div
      className={classes.codes}
      onClick={() => setshowCodes(false)}
    >
      {codesIn.map(el => {
        return (
          <div className={classes.code} key={el}>
            {datamatrixView(el)}
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={classes.wrapper}>
      {showCodes ? dataInView : null}
      <div className={classes.buttons}>
        <Button
          btnType="editButton"
          onClick={() => save({ out: codeOut, in: codesIn })}
        >
          Занести данные
        </Button>
        <div
          className={classes.counter}
          onClick={() => setshowCodes(true)}
        >
          {codesIn.length}
        </div>
        <Button btnType="closeButton" onClick={close}>
          X
        </Button>
      </div>
      <div className={classes.sscc}>
        <div className={classes.codesOut}>{codeOut}</div>
        <Button btnType="deleteButton" onClick={() => setcodeOut('')}>
          X
        </Button>
      </div>
      {error !== '' ? (
        <div className={classes.error}>
          <div className={classes.errorText}>{error}</div>
          <Button btnType="errorButton" onClick={() => seterror('')}>
            Продолжить
          </Button>
        </div>
      ) : (
        <MultiReader addCode={addCode} />
      )}
    </div>
  );
};

Unpack.propTypes = {
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default Unpack;
