export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_PENDING = 'GET_DOCUMENTS_PENDING';
export const GET_DOCUMENTS_FULFILLED = 'GET_DOCUMENTS_FULFILLED';
export const GET_DOCUMENTS_REJECTED = 'GET_DOCUMENTS_REJECTED';

export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_DOCUMENT_PENDING = 'GET_DOCUMENT_PENDING';
export const GET_DOCUMENT_FULFILLED = 'GET_DOCUMENT_FULFILLED';
export const GET_DOCUMENT_REJECTED = 'GET_DOCUMENT_REJECTED';

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_PENDING = 'UPDATE_DOCUMENT_PENDING';
export const UPDATE_DOCUMENT_FULFILLED = 'UPDATE_DOCUMENT_FULFILLED';
export const UPDATE_DOCUMENT_REJECTED = 'UPDATE_DOCUMENT_REJECTED';

export const SHOW_UNPACK = 'SHOW_UNPACK';
export const CREATE_UNPACK = 'CREATE_UNPACK';
export const CREATE_UNPACK_PENDING = 'CREATE_UNPACK_PENDING';
export const CREATE_UNPACK_FULFILLED = 'CREATE_UNPACK_FULFILLED';
export const CREATE_UNPACK_REJECTED = 'CREATE_UNPACK_REJECTED';

export const CHOOSE_PARTY = 'CHOOSE_PARTY';
export const CLOSE_DOCUMENT = 'CLOSE_DOCUMENT';
export const ADD_QRCODE = 'ADD_QRCODE';

export const GET_DATA = 'GET_DATA';
export const GET_DATA_PENDING = 'GET_DATA_PENDING';
export const GET_DATA_FULFILLED = 'GET_DATA_FULFILLED';
export const GET_DATA_REJECTED = 'GET_DATA_REJECTED';

export const CHANGE_TAB = 'CHANGE_TAB';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const LOGOUT = 'LOGOUT';

export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
