import React, { useState } from 'react';
import PropTypes from 'prop-types';

import QrReader from 'react-qr-reader';
import classes from './QRReader.module.css';

const QRreader = ({ addQRCode }) => {
  const [result, setresult] = useState({
    data: 'Нажмите кнопку для начала сканирования',
    pressed: false,
  });

  const handleScan = data => {
    if (result.pressed && data) {
      setresult({ data, pressed: false });
      addQRCode(data);
    }
  };
  const handleError = err => {
    console.error(err);
  };
  return (
    <div className={classes.wrapper}>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
      <div className={classes.result}>{result.data}</div>
      <div
        className={classes.button}
        onClick={() =>
          setresult({ data: 'Идет сканирование...', pressed: true })
        }
      >
        Сканировать
      </div>
    </div>
  );
};

QRreader.propTypes = {
  addQRCode: PropTypes.func,
};

QRreader.defaultProps = {
  addQRCode: () => {},
};

export default QRreader;
