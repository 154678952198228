import React from 'react';

import classes from './PageNotFound.module.css';

export const PageNotFound = () => {
  const redirectToMain = () => {};
  return (
    <div className={classes.wrapper}>
      <div className={classes.message}>Страница не найдена!</div>
      <div className={classes.button} onClick={redirectToMain}>
        Перейти на главную!
      </div>
    </div>
  );
};

export default PageNotFound;
