import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDocuments,
  openDocument,
  closeDocument,
  updateDocument,
} from '../../store/actions/dataActions';

// import Modal from '../../components/UI/Modal';
import Document from '../../components/Document';
import classes from './DocumentList.module.css';

export const DocumentList = () => {
  const documentList = useSelector(store => {
    return store.data.documentList;
  });
  const currentDocument = useSelector(store => {
    return store.data.currentDocument;
  });
  const activeTab = useSelector(store => store.menu.activeTab);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDocuments(activeTab));
    return () => {};
  }, [activeTab]);

  const documentListView = documentList.map(el => {
    return (
      <div
        className={
          el.edited ? classes.elementEdited : classes.element
        }
        key={el.uid}
        onClick={() => dispatch(openDocument(el.uid))}
      >
        <div className={classes.elementNumberDate}>
          <div>{el.number}</div>
          <div>{el.date}</div>
        </div>
        <div className={classes.elementData}>
          <div className={classes.elementPartner}>{el.consumer}</div>
          <div className={classes.elementSumm}>
            {el.summ.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>
        </div>
      </div>
    );
  });

  const documentListViewHeader = (
    <div className={classes.elementHeader} key="elementsHeader">
      <div className={classes.elementNumber}>
        <div>Номер</div>
        <div>Дата</div>
      </div>
      <div className={classes.elementData}>
        <div className={classes.elementPartner}>Контрагент</div>
        <div className={classes.elementSumm}>Сумма</div>
      </div>
    </div>
  );

  return (
    <>
      {currentDocument ? (
        <Document
          closeDoc={() => dispatch(closeDocument())}
          updateDoc={(id, codes) =>
            dispatch(updateDocument(id, codes))
          }
        />
      ) : null}
      <div className={classes.wrapper}>
        <div className={classes.list}>
          <div className={classes.header}>
            {documentListViewHeader}
          </div>
          <div className={classes.documentList}>
            {documentListView}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentList;
