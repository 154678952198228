import * as Constants from '../constants';

const initialState = {
  documentList: [],
  QRcodes: [],
  currentDocument: null,
  currentParty: null,
  isLoading: false,
  error: '',
  showUnpack: false,
};

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_DOCUMENTS_PENDING: {
      state = {
        ...state,
        isLoading: true,
        documentList: [],
        error: '',
      };
      break;
    }
    case Constants.GET_DOCUMENTS_FULFILLED: {
      state = {
        ...state,
        isLoading: false,
        documentList: action.payload.data.data,
      };
      break;
    }
    case Constants.GET_DOCUMENTS_REJECTED: {
      state = {
        ...state,
        isLoading: false,
        documentList: [
          {
            uid: '123',
            number: '00003',
            date: '12.06.2020',
            partner: 'тестовый',
            summ: '1230.00',
          },
          {
            uid: '1234',
            number: '000033',
            date: '10.06.2020',
            partner: 'тестовый 2',
            summ: '123000.00',
          },
          {
            uid: '12345',
            number: '0000765',
            date: '01.06.2020',
            partner: 'тестовый длинное имя контрагента',
            summ: '15000.00',
          },
        ],
        error: action.payload.response
          ? action.payload.response.data.message
          : 'no connection',
      };
      break;
    }
    case Constants.GET_DOCUMENT_PENDING: {
      state = {
        ...state,
        isLoading: true,
        currentDocument: null,
        error: '',
      };
      break;
    }
    case Constants.GET_DOCUMENT_FULFILLED: {
      if (action.payload.data.data.length > 0) {
        const doc = state.documentList.filter(
          el => el.id === action.payload.data.data[0].document
        );
        console.log(doc);

        state = {
          ...state,
          isLoading: false,
          currentDocument: {
            ...doc[0],
            tovars: action.payload.data.data,
          },
        };
      }
      break;
    }
    case Constants.GET_DOCUMENT_REJECTED: {
      state = {
        ...state,
        isLoading: false,
        error: action.payload.response
          ? action.payload.response.data.message
          : 'no connection',
      };
      break;
    }
    case Constants.GET_DATA_PENDING: {
      state = {
        ...state,
        isLoading: true,
      };
      break;
    }
    case Constants.GET_DATA_FULFILLED: {
      state = {
        ...state,
        isLoading: false,
        QRcodesDocument: action.payload.data,
      };
      break;
    }
    case Constants.GET_DATA_REJECTED: {
      state = {
        ...state,
        isLoading: false,
        QRcodesDocument: [],
      };
      break;
    }
    case Constants.CREATE_UNPACK_PENDING: {
      state = {
        ...state,
        isLoading: true,
        error: '',
      };
      break;
    }
    case Constants.CREATE_UNPACK_FULFILLED: {
      state = {
        ...state,
        isLoading: false,
        showUnpack: false,
      };
      break;
    }
    case Constants.CREATE_UNPACK_REJECTED: {
      state = {
        ...state,
        isLoading: false,
      };
      break;
    }
    case Constants.SHOW_UNPACK: {
      state = {
        ...state,
        showUnpack: action.payload,
      };
      break;
    }
    case Constants.ADD_QRCODE: {
      state = {
        ...state,
        QRcodes: [...state.QRcodes, action.payload],
      };
      break;
    }
    case Constants.CHOOSE_PARTY: {
      const party = state.currentDocument.tovars.filter(
        el =>
          el.uid === action.payload.uid &&
          el.uidParty === action.payload.uidParty
      );
      state = {
        ...state,
        currentParty: { ...party[0] },
      };
      break;
    }
    case Constants.CLOSE_DOCUMENT: {
      state = {
        ...state,
        currentDocument: null,
        currentParty: null,
      };
      break;
    }
    default:
  }
  return state;
};
