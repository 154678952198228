import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import QRReader from '../components/QRReader';
import MultiReader from '../components/MultiReader';
import { addQRCode } from '../store/actions/dataActions';
import classes from './Scan.module.css';

export const Scan = ({ dispatch, QRcodes }) => {
  const [activeTab, setactiveTab] = useState('scan');

  const scanHandler = data => {
    dispatch(addQRCode(data));
  };

  let showTab = null;
  if (activeTab === 'scan') {
    showTab = (
      <div className={classes.reader}>
        <MultiReader addCode={scanHandler} />
        {/* <QRReader addQRCode={scanHandler} /> */}
      </div>
    );
  } else {
    const items = QRcodes.map((el, ind) => {
      return (
        <div key={ind} className={classes.dataItem}>
          {el}
        </div>
      );
    });
    showTab = <div className={classes.dataList}>{items}</div>;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.tabs}>
        <div
          className={classes.tab}
          onClick={() => setactiveTab('scan')}
          data-testid="scan"
        >
          Скан
        </div>
        <div
          className={classes.tab}
          onClick={() => setactiveTab('data')}
          data-testid="data"
        >
          Данные
        </div>
      </div>
      {showTab}
    </div>
  );
};

Scan.propTypes = {
  dispatch: PropTypes.func,
  QRcodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Scan.defaultProps = {
  dispatch: () => {},
};

const mapStateToProps = store => {
  return {
    QRcodes: store.data.QRcodes,
  };
};

export default connect(mapStateToProps)(Scan);
