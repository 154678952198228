import React from 'react';
import PropTypes from 'prop-types';

import classes from './Input.module.css';

export const Input = ({ label, name, ...inputArgs }) => {
  return (
    <div
      className={
        inputArgs.type === 'checkbox'
          ? classes.wrapperCheckbox
          : classes.wrapper
      }
    >
      {inputArgs.type === 'checkbox' ? (
        <>
          <input className={classes.inputCheckbox} {...inputArgs} />
          <label className={classes.labelCheckbox} htmlFor={name}>
            {label}
          </label>
        </>
      ) : (
        <>
          <label className={classes.label} htmlFor={name}>
            {label}
          </label>
          <input className={classes.input} {...inputArgs} />
        </>
      )}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Input.defaultProps = {
  label: '',
};

export default Input;
