import React from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Scan from './pages/Scan';
import PageNotFound from './pages/PageNotFound';
import AuthForm from './pages/Auth';
import MainPage from './pages/MainPage';

const Routes = () => {
  const userId = useSelector(store => store.auth.userId);
  let routes = null;
  if (!userId) {
    routes = (
      <Switch>
        <Route path="/auth" component={AuthForm} />
        <Route path="/">
          <Redirect to="/auth" />
        </Route>
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/auth">
          <Redirect to="/" />
        </Route>
        <Route path="/scan" component={Scan} />
        {/* <Route path="/reciept" component={RecieptList} />
        <Route path="/sale/:document" component={SaleList} />
        <Route path="/sale" component={SaleList} />
        <Route path="/move" component={MoveGoods} />
        <Route path="/inventory" component={Inventory} />
        <Route path="/tasks" component={TaskList} />
        <Route path="/remnants" component={Remnants} /> */}
        <Route path="/" exact component={MainPage} />
        {/* component={PageNotFound}/> */}
        <Route path="*" component={PageNotFound} />
      </Switch>
    );
  }

  return <BrowserRouter>{routes}</BrowserRouter>;
};

export default Routes;
